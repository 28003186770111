export const statuses: TableModule = {
    name: "statuses",
    route: "spa.basedata.statuses",
    defaultOrder: {
        column: "customOrderSortByParent",
        direction: "asc"
    },
    columns: [
        {
            name: "name_management_section",
            sortable: true,
            pageLink: true,
            mobile: true
        },
        {
            name: "name_application_section",
            sortable: true,
            pageLink: true,
        },
        {
            name: "model_class",
            sortable: true,
            mobile: true
        },
        {
            name: "sort",
            sortable: true,
            mobile: true
        }
    ],
    filters: [
        {
            name: "name_management_section",
            column: "name_management_section",
            type: "string"
        },
        {
            name: "name_application_section",
            column: "name_application_section",
            type: "string"
        }
    ],
    quickFilters: [
        {
            name: "qf_modelClassProcessOnly",
            column: "model_class",
            value: "App\\Models\\Processes\\Process",
            operator: "="
        },
        {
            name: "qf_modelClassFormProcessOnly",
            column: "model_class",
            value: "App\\Models\\Processes\\FormProcess",
            operator: "="
        }
    ],
    tools: [
        {
            name: "statuses.create",
            isCreateAction: true,
            ability: [
                "create",
                "statuses"
            ]
        }
    ]
}