import {users} from './tableModules/users'
import {organisations} from "./tableModules/organisations";
import {loginProviders} from './tableModules/loginProviders'
import {contacts} from './tableModules/contacts'
import {fundingPrograms} from './tableModules/fundingPrograms'
import {forms} from './tableModules/forms'
import {regions} from './tableModules/regions'
import {parishes} from './tableModules/parishes'
import {processes} from './tableModules/processes'
import {userOrganisations} from "./tableModules/userOrganisations"
import {organisationUsers} from "./tableModules/organisationUsers"
import {statuses} from "./tableModules/statuses"

export const tableModules: TableModules = {
    users,
    fundingPrograms,
    forms,
    regions,
    parishes,
    regionParishes: {
        ...parishes,
        columns: parishes.columns.filter(column => column.name !== 'region_name').map(
            column => column.name === 'customName' ? {...column, width: 'w-1/2 md:w-1/3'} : column
        )
    },
    processes,
    organisations,
    userOrganisations,
    organisationUsers,
    testUsers: {
        ...users,
        name: 'testUsers'
    } as TableModule,
    contacts,
    loginProviders,
    statuses,
}
