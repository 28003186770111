export const processes: TableModule = {
    name: "processes",
    route: "spa.processes.processes",
    defaultOrder: {
        column: "id",
        direction: "desc"
    },
    columns: [
        {
            name: "serial_number",
            sortable: true,
            sortBy: "name",
            mobile: true,
            width: "w-1/2 sm:w-auto"
        },
        {
            name: "year",
            sortable: true,
            sortBy: "year_id",
            width: "w-1/2 sm:w-auto"
        },
        {
            name: "event",
            sortable: true,
            sortBy: "events.name",
            width: "w-1/2 sm:w-auto"
        },
        {
            name: "formProcesses",
            pageLink: true,
            mobile: true,
            width: "w-1/2 sm:w-auto"
        }
    ],
    filters: [
        {
            name: "id",
            column: "processes.id",
            type: "numeric"
        },
        {
            name: "year",
            column: "processes.year_id",
            type: "string"
        }
    ],
    quickFilters: [
        {
            name: "qf_ownOnly",
            column: "customFilterOwnOnly",
            value: "1",
            operator: "="
        }
    ],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "processes.delete",
            color: "rose",
            icon: "heroicons:trash"
        }
    ]
}