export const users: TableModule = {
    name: "users",
    route: "spa.settings.users",
    defaultOrder: {
        column: "lastname|firstname",
        direction: "asc"
    },
    columns: [
        {
            name: "customName",
            sortable: true,
            pageLink: true,
            sortBy: "lastname|firstname",
            mobile: true,
            width: "w-1/2 sm:w-auto"
        },
        {
            name: "email",
            sortable: true
        },
        {
            name: "organisation"
        },
    ],
    filters: [
        {
            name: "id",
            column: "users.id",
            type: "numeric"
        },
        {
            name: "firstname",
            column: "users.firstname",
            type: "string"
        },
        {
            name: "email",
            column: "users.email",
            type: "string"
        },
        {
            name: "organisation",
            column: "customFilterOrganisation",
            type: "string"
        }
    ],
    quickFilters: [
        {
            name: "qf_onlyEmployees",
            column: "customFilterRole",
            value: "user",
            operator: "="
        }
    ],
    tools: [
        {
            name: "general.export",
            icon: "heroicons:arrow-down-tray"
        },
        {
            name: "users.delete",
            color: "rose",
            icon: "heroicons:trash"
        },
        {
            name: "users.create",
            isCreateAction: true,
            ability: [
                "create",
                "users"
            ]
        }
    ]
}