export const useTableStore = () => {

    const name = ref(null);
    const route = ref(null);
    const defaultOrder = ref(null);
    const data = ref({}); // store previously fetched data
    const columns = ref(null);
    const filters = ref(null);
    const quickFilters = ref(null);
    const tools = ref(null);
    const idColumn = ref('id');

    const activeTool = ref(null);
    const loading = ref(false);
    const inverseSelection = ref(false);
    const selectedRows = ref([]);

    const listing = ref({
        search: "",
        orderBy: "id",
        orderDirection: "asc",
        perPage: 10,
        perPageOptions: [10, 50, 100],
        columns: 6,
        page: 1,
        lastPage: 1,
        total: 0,
        custom: {},
        filter: [],
        links: []
    })

    const rows = computed(() => {
        return data.value[listing.value.page] || [];
    })

    const selectedCount = computed(() => {
        return inverseSelection.value ? listing.value.total - selectedRows.value.length : selectedRows.value.length;
    })

    function resetSelection() {
        inverseSelection.value = false;
        selectedRows.value = [];
    }

    async function fetchData(fresh = false) {
        const {$apiRoute} = useNuxtApp()

        loading.value = true

        if (fresh) {
            listing.value.page = 1;
            data.value = {}
        } else if (data.value[listing.value.page]) {
            loading.value = false
            return
        }

        $lara.get($apiRoute(route.value + '.index'), {
            params: {...listing.value}
        }).then((response: Object) => {
            data.value[listing.value.page] = response.data
            listing.value.page = response.current_page
            listing.value.lastPage = response.last_page
            listing.value.total = response.total
            listing.value.links = response.links
        }).catch(error => {
            // console.log(error)
        }).finally(() => {
            loading.value = false
        })
    }

    async function postToolData(tool: string, data: Object = {}) {
        const {$apiRoute} = useNuxtApp()
        return $lara.post($apiRoute(route.value + '.action'), {
            body: {
                ...listing.value,
                ...listing.value.custom,
                selection: {
                    inverseSelection: inverseSelection.value ?? false,
                    selectedIds: selectedRows.value ?? []
                },
                tool,
                toolData: data
            }
        }).catch(error => {
            console.log(error)
        })
    }

    function initialize(module: string) {
        // TODO cache columns/filters/etc
        const moduleDefinition = tableModules[module];
        name.value = moduleDefinition.name;
        route.value = moduleDefinition.route;
        defaultOrder.value = moduleDefinition.defaultOrder ?? null
        idColumn.value = moduleDefinition.idColumn ?? 'id';
        columns.value = moduleDefinition.columns;
        filters.value = moduleDefinition.filters ?? null;
        quickFilters.value = moduleDefinition.quickFilters ?? null;
        tools.value = moduleDefinition.tools ?? null;

        if(defaultOrder.value) {
            listing.value.orderBy = defaultOrder.value.column
            listing.value.orderDirection = defaultOrder.value.direction
        }
    }

    return {
        loading,
        columns,
        filters,
        quickFilters,
        tools,
        rows,
        listing,
        inverseSelection,
        selectedRows,
        selectedCount,
        activeTool,
        defaultOrder,
        idColumn,
        initialize,
        fetchData,
        resetSelection,
        postToolData
    };
};
